<template>
    <div class="wrap-dashboard">
        <div class="main-content">
            <withdraw-on-temp></withdraw-on-temp>
        </div>
    </div>
</template>

<script>
import WithdrawOnTemp from "../components/withdrawal/WithdrawOnTemp.vue";
export default {
    components: { WithdrawOnTemp },
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            loadingData: false,
            isShowfilter: false,
            owner: "",
            serialNumber: "",
            status: "",
            offset: 0,
            typeWithdraw: "onexchange",
            form: {
                startDate: "",
                endDate: "",
            },
        };
    },
    mounted() {
        this.$store.dispatch("loadWithdrawal", {
            startDate: this.form.startDate,
            endDate: this.form.endDate,
            limit: this.perPage,
            currentPage: this.currentPage,
            type: this.typeWithdraw,
            owner: this.owner,
            serialNumber: this.serialNumber,
            status: this.status,
        });
        this.$store.dispatch("getClientData", this.typeWithdraw);
    },
    methods: {
        handlePageChange(value) {
            this.$store.dispatch("loadWithdrawal", {
                startDate: this.form.startDate,
                endDate: this.form.endDate,
                limit: this.perPage,
                currentPage: value,
                type: this.typeWithdraw,
                owner: this.owner,
                serialNumber: this.serialNumber,
                status: this.status,
            });
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        filterTypeWithdraw(value) {
            this.isShowfilter = false;
            this.typeWithdraw = value;
            this.$store.dispatch("getClientData", this.typeWithdraw);
            this.$store.dispatch("loadWithdrawal", {
                startDate: this.form.startDate,
                endDate: this.form.endDate,
                limit: this.perPage,
                currentPage: this.currentPage,
                type: value,
                owner: this.owner,
                serialNumber: this.serialNumber,
                status: this.status,
            });
        },
        filterTable(event) {
            this.$store.dispatch("loadWithdrawal", {
                startDate: event.startDate,
                endDate: event.endDate,
                limit: this.perPage,
                currentPage: this.currentPage,
                type: this.typeWithdraw,
                owner: event.owner,
                serialNumber: event.serial_number,
                status: event.status,
            });

            this.form.startDate = event.startDate;
            this.form.endDate = event.endDate;
            this.owner = event.owner;
            this.serialNumber = event.serial_number;
            this.status = event.status;
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
        loadDataWithdrawal() {
            return this.$store.state.bullionModule.dataWithdrawal;
        },
        totalData() {
            return this.$store.state.bullionModule.lengthDataWithdrawal;
        },
        getDataClient() {
            return this.$store.state.bullionModule.clientData;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.nav-tab {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #636366;
    padding: 0.8rem 0;
}

.tab {
    padding: 0.8rem 1rem;
    cursor: pointer;
}

.tab:hover,
.activeTab {
    color: var(--scondary-color);
    border-bottom: 2px solid var(--scondary-color);
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

label {
    width: 50%;
}

.select-input,
input {
    width: 100%;
}

.form-group {
    display: flex;
}

.select-input,
input {
    border-radius: 5px;
    border: 1px solid #454545;
    padding: 0 10px;
    font-size: 13px;
}

.buuton-add {
    text-align: right;
    font-size: 13px;
}
.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.filter {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;
    flex-wrap: wrap;
}

.wrap-input {
    margin-bottom: 10px;
}
.label-input {
    width: 109px;
}
select {
    width: 130px;
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.status-withdrawal {
    padding: 5px;
    display: inherit;
}

.request {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(252, 108, 4);
    border-radius: 5px;
}
.abx {
    /* background: rgba(172, 143, 26, 0.1); */
    font-weight: 600;
    color: rgb(172, 143, 26);
    border-radius: 5px;
}
.operator {
    /* background: rgba(89, 37, 25, 0.1); */
    font-weight: 600;
    color: rgb(89, 37, 25);
    border-radius: 5px;
}
.kbi {
    /* background: rgba(60, 60, 92, 0.1); */
    font-weight: 600;
    color: rgb(60, 60, 92);
    border-radius: 5px;
}
.shipping {
    /* background: rgba(188, 180, 179, 0.1); */
    font-weight: 600;
    color: rgb(54, 255, 14);
    border-radius: 5px;
}
.handover {
    /* background: rgba(50, 90, 115, 0.1); */
    font-weight: 600;
    color: rgb(50, 90, 115);
    border-radius: 5px;
}
.kprk {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(252, 108, 4);
    border-radius: 5px;
}
.nasabah {
    /* background: rgba(40, 167, 69, 0.1); */
    font-weight: 600;
    color: rgb(40, 167, 69);
    border-radius: 5px;
}
.decline {
    /* background: rgba(220, 53, 69, 0.1); */
    font-weight: 600;
    color: rgb(220, 53, 69);
    border-radius: 5px;
}
</style>
